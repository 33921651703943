import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const Seo = ({ description, keywords, title, image, url, author }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title,
          image,
          siteUrl,
          description,
          keywords
        }
      }
    }
  `);

  // If no props user site seo
  const metaDescription = description || site.siteMetadata.description;
  const metaTitle = title || site.siteMetadata.title;
  const metaAuthor = author || site.siteMetadata.author;
  const metaImage = image || site.siteMetadata.image;
  const metaUrl = url || site.siteMetadata.siteUrl;
  const metaKeywords = keywords || site.siteMetadata.keywords;

  const metaData = [
    { property: 'og:title', content: metaTitle },
    { property: 'og:description', content: metaDescription },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: metaUrl },
    { property: 'og:image', content: metaImage },
    { name: 'description', content: metaDescription },
    { name: 'twitter:card', content: `summary_large_image` },
    { name: 'twitter:title', content: metaTitle },
    { name: 'twitter:description', content: metaDescription },
    { name: 'twitter:image', content: metaImage },
    metaAuthor ? { name: 'twitter:creator', content: metaAuthor} : {}
  ].concat(
    metaKeywords && metaKeywords.length > 0 ? { name: 'keywords', content: metaKeywords.join(', ')} : []
  );

  return (
    <Helmet
      title={metaTitle}
      meta={metaData}
    >
      <link href="https://fonts.googleapis.com/css2?family=Roboto&display=swap" rel="stylesheet"></link>
    </Helmet>
  )
};

export default Seo;